import * as gtm from '../custom-files/gtm';

const initializeGTM = () => {
  // // Type the code for the body of your function or hook here.
  // // Functions can be triggered via Button/Touchable actions.
  // // Hooks are run per ReactJS rules.
  // /* String line breaks are accomplished with backticks ( example: `line one
  // line two` ) and will not work with special characters inside of quotes ( example: "line one line two" ) */
  // console.log(gtm)
  // const { tagManager } =  gtm;
  // const tagManagerArgs = {
  //     gtmId: 'AW-947671948'
  // };
  // tagManager.initialize(tagManagerArgs);
};

export default initializeGTM;
